import { FC, useCallback, useMemo, useState } from 'react';
import { MemberRecipient, PeopleType, UncontrolledCopyRequest } from '../../models/Distribution';
import { ModalContext } from '../../contexts/ModalContext';
import StandardModal from '../shared/modal/variants/StandardModal';
import { useTranslation } from 'react-i18next';
import { Input } from '../shared/form-control/Input';
import MultiTextField from '../shared/form-control/MultiTextField';
import Checkbox from '../shared/form-control/Checkbox';
import ClientService from '../../services/ClientService';
import { Option } from '../Option';
import DropdownSelect from '../shared/form-control/DropdownSelect';
import { emailRegex } from '../../utils/EmailUtils';
import { components, MultiValueProps } from 'react-select';
import { ImageSize, ProfileImageStack } from '../ownership/ProfileImageStack';
import DocumentService from '../../services/DocumentService';
import { ToastType, useToasts } from '../../contexts/ToastContext';
import UserListRenderer from '../ownership/UserListRenderer';

type Props = {
  open: boolean;
  onClose: () => void;
  clientFormId: string;
  clientFormVersion: number;
  clientFormSubtitle: string;
};

const UncontrolledCopyModal: FC<Props> = (props) => {
  const { open, onClose, clientFormSubtitle, clientFormId, clientFormVersion } = props;
  const { t } = useTranslation(['distribution']);
  const defaultSubject = t('uncontrolled-copy.send-modal.fields.subject.default-value', { title: clientFormSubtitle });
  const [subject, setSubject] = useState(defaultSubject);
  const [message, setMessage] = useState('');
  const [sendCopy, setSendCopy] = useState(false);
  const [emailAddresses, setEmailAddresses] = useState<string[]>([]);
  const [members, setMembers] = useState<MemberRecipient[]>([]);
  const isValid = useMemo(
    () => (members.length > 0 || emailAddresses.length > 0) && subject.length && message.length,
    [emailAddresses.length, members.length, message.length, subject.length],
  );
  const toaster = useToasts();
  const [sending, setSending] = useState(false);

  const send = useCallback(() => {
    setSending(true);
    const request: UncontrolledCopyRequest = {
      clientFormId: clientFormId,
      clientFormVersion: clientFormVersion,
      recipients: { members: members, emailAddresses: emailAddresses },
      subject: subject,
      message: message,
      includeSelfCopy: sendCopy,
    };
    DocumentService.sendUncontrolledCopy(request)
      .then(() => {
        toaster.addToast({
          title: t('uncontrolled-copy.toasters.success.title'),
          description: t('uncontrolled-copy.toasters.success.description'),
          type: ToastType.SUCCESS,
          expiresInMs: 5000,
        });
      })
      .catch(() => {
        toaster.addToast({
          title: t('uncontrolled-copy.toasters.failure.title'),
          description: t('uncontrolled-copy.toasters.failure.description'),
          type: ToastType.ERROR,
        });
      })
      .finally(() => {
        setSending(false);
        setMembers([]);
        setEmailAddresses([]);
        setSubject(defaultSubject);
        setMessage('');
        setSendCopy(false);
        onClose();
      });
  }, [clientFormId, clientFormVersion, defaultSubject, emailAddresses, members, message, onClose, sendCopy, subject, t, toaster]);

  const selectedMembers = useMemo(() => {
    return [
      ...members.map((m) => {
        return {
          id: m.id,
          value: m.type,
          text: m.fullname,
        };
      }),
      ...emailAddresses.map((e) => ({ id: e, text: e, value: -1 })),
    ];
  }, [emailAddresses, members]);

  const onAddMember = useCallback((value: Option<string, number>[]) => {
    setMembers(value.filter((x) => x.value !== -1).map((x) => ({ id: x.id, type: x.value, fullname: x.text })));
  }, []);

  const onAddEmailAddress = useCallback((email: string) => {
    setEmailAddresses((prev) => [...prev, email]);
  }, []);

  const onRemove = useCallback((item: Option<string, number>) => {
    if (item.value === -1) {
      setEmailAddresses((prev) => prev.filter((x) => x !== item.id));
    } else {
      setMembers((prev) => prev.filter((x) => x.id !== item.id));
    }
  }, []);

  const MultiValue = useMemo(() => {
    return function MultiValueWrapper(props: MultiValueProps<Option<string, number>>) {
      const { id, text, value } = props.data;
      const [firstName, ...lns] = text.split(' ');
      const lastName = lns.join(' ');
      return (
        <components.MultiValue {...props} className="flex h-[31px] items-center gap-1 !rounded-lg">
          <ProfileImageStack
            users={[{ firstName: firstName, lastName: lastName, id: value === PeopleType.Member ? id : undefined }]}
            size={ImageSize.XS}
          />
          <span>{text}</span>
        </components.MultiValue>
      );
    };
  }, []);

  return (
    <ModalContext.Provider value={{ open, onClose, modalWidth: 'w-2/5 min-w-[900px]' }}>
      <StandardModal
        title={t('uncontrolled-copy.send-modal.title')}
        confirmButtonTitle={t('uncontrolled-copy.send-modal.confirm')}
        confirmDisabled={!isValid}
        confirmLoading={sending}
        onConfirmClick={send}
        onCancelClick={onClose}
      >
        <DropdownSelect
          value={selectedMembers}
          onChange={onAddMember}
          allowCreation
          allowCreationRegex={emailRegex}
          autoAddChars={[';', ',', ' ']}
          isMulti
          onCreateOption={onAddEmailAddress}
          customListRenderer={UserListRenderer}
          onRemove={onRemove}
          label={t('uncontrolled-copy.send-modal.fields.recipients.label')}
          placeholder={t('uncontrolled-copy.send-modal.fields.recipients.placeholder')}
          isAsync
          loadOptions={(inputValue, callback) => {
            ClientService.getPeople({ searchTerm: inputValue }).then((res) => {
              callback(
                res.data.map((x) => ({
                  id: x.id,
                  text: `${x.firstName} ${x.lastName}`,
                  value: x.type,
                })),
              );
            });
          }}
          customComponents={{ MultiValue }}
        />
        <Input
          placeholder={t('uncontrolled-copy.send-modal.fields.subject.placeholder')}
          label={t('uncontrolled-copy.send-modal.fields.subject.label')}
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          maxLength={150}
        />
        <MultiTextField
          placeholder={t('uncontrolled-copy.send-modal.fields.message.placeholder')}
          label={t('uncontrolled-copy.send-modal.fields.message.label')}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          maxLength={250}
          className="resize-none"
        />
        <Checkbox label={t('uncontrolled-copy.send-modal.fields.copy-checkbox')} value={sendCopy} onChange={(value) => setSendCopy(value)} />
      </StandardModal>
    </ModalContext.Provider>
  );
};

export default UncontrolledCopyModal;
