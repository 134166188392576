import { FC } from 'react';
import StandardModal from '../shared/modal/variants/StandardModal';
import { StepProps } from './DistributionWizard';
import DistributionStartMessage from './DistributionStartMessage';

const StartDistributionStep: FC<StepProps> = (props) => {
  const { setCurrentStep } = props;

  return <StandardModal>{setCurrentStep && <DistributionStartMessage onStartClick={() => setCurrentStep('members')} />}</StandardModal>;
};

export default StartDistributionStep;
