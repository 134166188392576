import { useQuery } from '@tanstack/react-query';
import { currentClientAtom } from '../recoil/atoms/Clients';
import { useRecoilValue } from 'recoil';
import ClientContactService from '../services/ClientContactService';

const useFetchClientContacts = () => {
  const currentClient = useRecoilValue(currentClientAtom);
  return useQuery({
    queryKey: ['clientContacts', currentClient?.id],
    staleTime: Infinity,
    queryFn: () => {
      return ClientContactService.getAllPaged(currentClient!.id, { pageSize: 9999 }).then((res) => res.data);
    },
  });
};

export default useFetchClientContacts;
