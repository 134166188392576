import { ComponentProps, FC, Fragment, useCallback, useMemo, useState } from 'react';
import { StepProps } from './DistributionWizard';
import { useTranslation } from 'react-i18next';
import StandardModal from '../shared/modal/variants/StandardModal';
import WizardStepsIndicator from '../shared/wizard/WizardStepsIndicator';
import { Heading, HeadingSize } from '../shared/text/Heading';
import { DateInterval, DateIntervalKeys } from '../../models/DateInterval';
import Checkbox, { SliderSize } from '../shared/form-control/Checkbox';
import { DistrubutiuonSchedulingType } from '../../models/Distribution';
import { DatePicker, DatePickerType } from '../shared/form-control/DatePicker';
import { ButtonType } from '../shared/form-control/Button';
import DateUtils from '../../utils/DateUtils';
import RadioButton from '../shared/form-control/RadioButton';
import { Input, InputStyle } from '../shared/form-control/Input';
import DropdownSelect, { DropdownSize } from '../shared/form-control/DropdownSelect';

const DistributionPreferencesStep: FC<StepProps> = (props) => {
  const { setCurrentStep, preferences, setPreferences, doCreate } = props;
  const { t } = useTranslation(['distribution', 'common']);
  const [saving, setSaving] = useState(false);

  const intervals = [
    { id: DateInterval.DAY.toString(), value: DateInterval.DAY, text: t(DateIntervalKeys[DateInterval.DAY]) },
    { id: DateInterval.WEEK.toString(), value: DateInterval.WEEK, text: t(DateIntervalKeys[DateInterval.WEEK]) },
    { id: DateInterval.MONTH.toString(), value: DateInterval.MONTH, text: t(DateIntervalKeys[DateInterval.MONTH]) },
    { id: DateInterval.YEAR.toString(), value: DateInterval.YEAR, text: t(DateIntervalKeys[DateInterval.YEAR]) },
  ];

  const preferencesValid = useMemo(() => {
    if (preferences.scheduleType === DistrubutiuonSchedulingType.FixedDate && !preferences.scheduleFixedDate) {
      return false;
    }
    if (preferences.linkExpirationAmount === null && !preferences.linkExpirationDate) {
      return false;
    }

    return true;
  }, [preferences.linkExpirationAmount, preferences.linkExpirationDate, preferences.scheduleFixedDate, preferences.scheduleType]);

  const createDistribution = useCallback(() => {
    setSaving(true);
    doCreate && doCreate();
  }, [doCreate]);

  const Wrapper = setCurrentStep ? StandardModal : Fragment;
  const wrapperProps = setCurrentStep
    ? ({
        title: t('steps.title'),
        confirmButtonTitle: t('steps.done'),
        cancelButtonTitle: t('steps.back'),
        onConfirmClick: createDistribution,
        onCancelClick: () => setCurrentStep && setCurrentStep('settings'),
        confirmDisabled: !preferencesValid,
        confirmLoading: saving,
      } as ComponentProps<typeof StandardModal>)
    : {};

  return (
    <Wrapper {...wrapperProps}>
      {setCurrentStep && (
        <WizardStepsIndicator stepNames={['members', 'settings', 'preferences']} activeStepIndex={2} onStepChange={setCurrentStep} />
      )}

      {setCurrentStep && (
        <div className="mt-2">
          <Heading size={HeadingSize.H5} className="mb-4 font-medium" textColor="text-black">
            {t('steps.settings.step-title')}
          </Heading>
        </div>
      )}

      <div className="mt-4">
        <div className="font-medium text-black">{t('steps.preferences.scheduling.title')}</div>
        <div className="flex">
          <RadioButton
            value={preferences.scheduleType === DistrubutiuonSchedulingType.Immediate}
            onChange={() => setPreferences((prev) => ({ ...prev, scheduleType: DistrubutiuonSchedulingType.Immediate, scheduleFixedDate: null }))}
            label={t('steps.preferences.scheduling.options.immediate')}
          />
        </div>
        <div className="flex">
          <RadioButton
            value={preferences.scheduleType === DistrubutiuonSchedulingType.EffectiveDate}
            onChange={() => setPreferences((prev) => ({ ...prev, scheduleType: DistrubutiuonSchedulingType.EffectiveDate, scheduleFixedDate: null }))}
            label={t('steps.preferences.scheduling.options.effective-date')}
          />
        </div>
        <div className="flex justify-between gap-3">
          <RadioButton
            value={preferences.scheduleType === DistrubutiuonSchedulingType.FixedDate}
            onChange={() => setPreferences((prev) => ({ ...prev, scheduleType: DistrubutiuonSchedulingType.FixedDate }))}
            label={t('steps.preferences.scheduling.options.fixed-date')}
          />
          {preferences.scheduleType === DistrubutiuonSchedulingType.FixedDate && (
            <DatePicker
              date={preferences.scheduleFixedDate ? new Date(preferences.scheduleFixedDate) : null}
              onChange={(value) => setPreferences((prev) => ({ ...prev, scheduleFixedDate: value!.toISOString() }))}
              type={DatePickerType.BUTTON}
              buttonType={ButtonType.TERTIARY}
              buttonContent={
                preferences.scheduleFixedDate
                  ? DateUtils.formatDate(new Date(preferences.scheduleFixedDate))
                  : t('steps.preferences.scheduling.pick-date')
              }
              buttonClassName="text-black"
              notBefore={new Date()}
            />
          )}
        </div>
      </div>

      <div className="mt-4">
        <div className="font-medium text-black">{t('steps.preferences.reminder.title')}</div>
        <div className="flex">
          <RadioButton
            value={preferences.reminderFrequencyValue === null}
            onChange={() => setPreferences((prev) => ({ ...prev, reminderFrequencyValue: null, reminderFrequencyInterval: null }))}
            label={t('steps.preferences.reminder.options.once-off')}
          />
        </div>
        <div className="flex justify-between gap-3">
          <RadioButton
            value={preferences.reminderFrequencyValue !== null}
            onChange={() => setPreferences((prev) => ({ ...prev, reminderFrequencyValue: 1, reminderFrequencyInterval: DateInterval.DAY }))}
            label={t('steps.preferences.reminder.options.recurring')}
          />
          <div className="flex w-[200px] justify-start gap-4 px-2">
            <div className="w-14 flex-shrink-0">
              <Input
                style={InputStyle.MINIMAL}
                disabled={preferences.reminderFrequencyValue === null}
                type="number"
                className="text-center"
                value={preferences.reminderFrequencyValue ? `${preferences.reminderFrequencyValue}` : ''}
                min={1}
                onChange={(e) => setPreferences((prev) => ({ ...prev, reminderFrequencyValue: parseInt(e.target.value, 10) }))}
              />
            </div>
            <div className="flex-grow">
              <DropdownSelect
                size={DropdownSize.S}
                disabled={preferences.reminderFrequencyValue === null}
                className="w-40 text-left"
                value={intervals.find((x) => x.id === preferences.reminderFrequencyInterval?.toString())}
                options={intervals}
                onChange={(option) => {
                  setPreferences((prev) => ({ ...prev, reminderFrequencyInterval: option.value }));
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <div className="font-medium text-black">{t('steps.preferences.notification.title')}</div>
        <Checkbox
          value={preferences.notifyOnReady}
          onChange={(value) => setPreferences((prev) => ({ ...prev, notifyOnReady: value }))}
          label={t('steps.preferences.notification.ready-for-acknowlagement')}
          labelBeforeCheckbox
          slider
          sliderSize={SliderSize.S}
        />
        <Checkbox
          value={preferences.notifyOnSuccessful}
          onChange={(value) => setPreferences((prev) => ({ ...prev, notifyOnSuccessful: value }))}
          label={t('steps.preferences.notification.acknowledged')}
          labelBeforeCheckbox
          slider
          sliderSize={SliderSize.S}
        />
      </div>

      <div className="mt-4">
        <div className="font-medium text-black">{t('steps.preferences.expiration.title')}</div>

        <div className="flex justify-between gap-3">
          <RadioButton
            value={preferences.linkExpirationAmount !== null}
            onChange={() =>
              setPreferences((prev) => ({
                ...prev,
                linkExpirationAmount: 2,
                linkExpirationInterval: DateInterval.WEEK,
                linkExpirationFixedDate: null,
              }))
            }
            label={t('steps.preferences.expiration.options.sliding-window')}
          />
          <div className="flex w-[200px] justify-start gap-4 px-2">
            <div className="w-14 flex-shrink-0">
              <Input
                disabled={preferences.linkExpirationAmount === null}
                type="number"
                className="text-center"
                value={preferences.linkExpirationAmount ? `${preferences.linkExpirationAmount}` : ''}
                min={1}
                onChange={(e) => setPreferences((prev) => ({ ...prev, linkExpirationAmount: parseInt(e.target.value, 10) }))}
                style={InputStyle.MINIMAL}
              />
            </div>
            <div className="flex-grow">
              <DropdownSelect
                size={DropdownSize.S}
                disabled={preferences.linkExpirationAmount === null}
                className="w-40 text-left"
                value={intervals.find((x) => x.id === preferences.linkExpirationInterval?.toString())}
                options={intervals}
                onChange={(option) => {
                  setPreferences((prev) => ({ ...prev, linkExpirationInterval: option.value }));
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between gap-3">
          <RadioButton
            value={preferences.linkExpirationAmount === null}
            onChange={() => setPreferences((prev) => ({ ...prev, linkExpirationAmount: null, linkExpirationInterval: null }))}
            label={t('steps.preferences.scheduling.options.fixed-date')}
          />
          {preferences.linkExpirationAmount === null && (
            <DatePicker
              date={preferences.linkExpirationDate ? new Date(preferences.linkExpirationDate) : null}
              onChange={(value) => setPreferences((prev) => ({ ...prev, linkExpirationDate: value!.toISOString() }))}
              type={DatePickerType.BUTTON}
              buttonType={ButtonType.TERTIARY}
              buttonContent={
                preferences.linkExpirationDate
                  ? DateUtils.formatDate(new Date(preferences.linkExpirationDate))
                  : t('steps.preferences.scheduling.pick-date')
              }
              buttonClassName="text-black"
              notBefore={new Date()}
            />
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default DistributionPreferencesStep;
