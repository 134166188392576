import { FC } from 'react';
import Tooltip from '../Tooltip';
import { ProfileImageStack } from '../../ownership/ProfileImageStack';
import { PeopleType } from '../../../models/Distribution';

interface ProfileCardProps {
  userId?: string;
  peopleType?: PeopleType;
  name: string;
  email: string;
}

const ProfileCard: FC<ProfileCardProps> = ({ userId, peopleType, name, email }) => {
  const [fn, ...lns] = name.split(' ');
  const ln = lns.join(' ');
  return (
    <div className="flex min-w-0 max-w-full items-center space-x-2">
      <ProfileImageStack users={[{ firstName: fn, lastName: ln, id: peopleType === PeopleType.Member ? userId : undefined }]} />
      <Tooltip
        text={
          <>
            <div>{name}</div>
            <div>{email}</div>
          </>
        }
        truncatedTextMode
      >
        {(tooltip) => (
          <div {...tooltip} className="truncate whitespace-nowrap">
            <div className="truncate font-medium">{name}</div>
            <div className="text-gray-2 truncate">{email}</div>
          </div>
        )}
      </Tooltip>
    </div>
  );
};

export default ProfileCard;
