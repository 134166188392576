import { ComponentRef, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ModalContext } from '../../contexts/ModalContext';
import StandardModal from '../shared/modal/variants/StandardModal';
import { TabStrip } from '../shared/tab-strip/TabStrip';
import { useTranslation } from 'react-i18next';
import { DistributionMember, DistributionResponse, DistributionMemberStatus, PeopleResponse, PeopleType } from '../../models/Distribution';
import DistributionMembersStep from './DistributionMembersStep';
import { StepProps } from './DistributionWizard';
import useFetchClientUsers from '../../hooks/useFetchClientUsers';
import DistributionLogsStep from './DistributionLogsStep';
import DistributionSettingsStep from './DistributionSettingsStep';
import DistributionPreferencesStep from './DistributionPreferencesStep';
import DistributionService from '../../services/DistributionService';
import { recordMap, toGroupedRecord } from '../../utils/ListUtils';
import { EventSystem } from '../../events/EventSystem';
import useFetchClientContacts from '../../hooks/useFetchClientContacts';
import { Trans } from 'react-i18next';
import { ToastType, useToasts } from '../../contexts/ToastContext';
import DistributionInviteNewStep from './DistributionInviteNewStep';
import { useDistributionPermissions } from '../../hooks/permissions/useDistributionPermissions';
import DistributionUncontrolledCopyLogs from './DistributionUncontrolledCopyLogs';

type Props = {
  open: boolean;
  onClose: () => void;
  distribution: DistributionResponse;
  clientFormSubtitle: string;
};

const MEMBERS_TAB_ID = 'members' as const;

const DistributionManageModal: FC<Props> = (props) => {
  const { open, onClose, distribution, clientFormSubtitle } = props;

  const { canMaintainDistribution } = useDistributionPermissions(distribution);
  const [tabId, setTabId] = useState<string>(MEMBERS_TAB_ID);
  const [inviteMode, setInviteMode] = useState(false);
  const [inviteValid, setInviteValid] = useState(false);
  const inviteModalRef = useRef<ComponentRef<typeof DistributionInviteNewStep>>(null);

  const toasts = useToasts();
  const [dirty, setDirty] = useState(false);
  const [saving, setSaving] = useState(false);

  const [members, setMembers] = useState<DistributionMember[]>(distribution.members);
  const [settings, setSettings] = useState(distribution.settings);
  const [preferences, setPreferences] = useState(distribution.preferences);

  const { data: contacts } = useFetchClientContacts();
  const { data: clientUsers, refetch } = useFetchClientUsers();

  const readOnly = useMemo(() => !canMaintainDistribution, [canMaintainDistribution]);

  useEffect(() => {
    if (open) return;

    setMembers(distribution.members);
    setSettings(distribution.settings);
    setPreferences(distribution.preferences);
    setDirty(false);
    setSaving(false);
    setInviteMode(false);
    setInviteValid(false);
  }, [distribution.members, distribution.preferences, distribution.settings, open]);

  const { t } = useTranslation('distribution');

  const mapMembers = useCallback(
    (toMap: DistributionMember[]) => {
      return (
        toMap
          .map((member) => {
            const person =
              member.type === PeopleType.Contact
                ? (contacts ?? []).find((x) => x.id === member.memberId)
                : (clientUsers ?? []).find((x) => x.id === member.memberId);
            return {
              id: member.memberId,
              firstName: (person && person.firstName) || '',
              lastName: (person && person.lastName) || '',
              type: member.type,
            } as PeopleResponse;
          })
          // sort on type first, then on full name
          .sort((a, b) => {
            if (a.type !== b.type) {
              return a.type === PeopleType.Member ? -1 : 1;
            }

            return `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`);
          })
      );
    },
    [clientUsers, contacts],
  );

  const setMembersInternal = useCallback(
    (people: PeopleResponse[] | ((value: PeopleResponse[]) => PeopleResponse[])) => {
      setDirty(true);
      setMembers((prev) => {
        if (typeof people === 'function') {
          people = people(mapMembers(prev));
        }

        return people.map((person) => {
          const existingMember = members.find((x) => x.memberId === person.id);

          return {
            distributionId: distribution.id,
            memberId: person.id,
            type: person.type,
            status: existingMember?.status ?? DistributionMemberStatus.Created,
            statusUtc: existingMember?.statusUtc ?? '',
          } as DistributionMember;
        });
      });
    },
    [distribution.id, mapMembers, members],
  );

  useEffect(() => {
    setDirty(true);
  }, [settings, preferences]);

  useEffect(() => {
    setInviteMode(false);
    setInviteValid(false);
  }, [tabId]);

  const sharedProps = useMemo<StepProps>(() => {
    return {
      members: mapMembers(members),
      setMembers: setMembersInternal,
      settings,
      setSettings,
      preferences,
      setPreferences,
      readOnly,
    };
  }, [mapMembers, members, preferences, readOnly, setMembersInternal, settings]);

  const saveChanges = useCallback(() => {
    const payloadMembers = recordMap(toGroupedRecord(members, 'type'), (_, value) => value.map((x) => x.memberId));

    setSaving(true);
    DistributionService.updateDistribution(distribution.id, { members: payloadMembers, settings, preferences }).then((res) => {
      EventSystem.fireEvent('distribution-updated', res.data);
      onClose();

      toasts.addToast({
        title: (
          <span className="font-normal">
            <Trans
              t={t}
              i18nKey="manage-update-tooltip"
              values={{ name: clientFormSubtitle }}
              components={{ Bold: <span className="font-medium" /> }}
            />
          </span>
        ),
        type: ToastType.SUCCESS,
        expiresInMs: 3000,
      });
    });
  }, [clientFormSubtitle, distribution.id, members, onClose, preferences, settings, t, toasts]);

  const triggerInvite = useCallback(() => {
    setSaving(true);
    inviteModalRef.current
      ?.triggerInvite()
      .then(() => {
        refetch();
      })
      .finally(() => {
        setInviteMode(false);
        setSaving(false);
      });
  }, [refetch]);

  useEffect(() => {
    const handler = (member: DistributionMember) => {
      setMembers((prev) => prev.map((x) => (x.memberId === member.memberId ? { ...x, reminderSentUtc: new Date().toISOString() } : x)));
      EventSystem.fireEvent('distribution-updated', {
        ...distribution,
        members: distribution.members.map((x) => (x.memberId === member.memberId ? { ...x, reminderSentUtc: new Date().toISOString() } : x)),
      });
    };

    EventSystem.listen('distribution-member-reminded', handler);
    return () => {
      EventSystem.stopListening('distribution-member-reminded', handler);
    };
  }, [distribution]);

  return (
    <ModalContext.Provider value={{ open, onClose, modalWidth: 'w-2/5 min-w-[900px]' }}>
      <StandardModal
        title={readOnly ? t('view-title') : t('manage-title')}
        cancelButtonTitle={readOnly ? t('steps.back') : inviteMode ? t('steps.invite-new.cancel') : undefined /* default */}
        onCancelClick={inviteMode ? () => setInviteMode(false) : onClose}
        confirmButtonTitle={inviteMode ? t('steps.invite-new.confirm') : undefined /* default */}
        onConfirmClick={readOnly ? undefined : inviteMode ? triggerInvite : saveChanges}
        confirmDisabled={inviteMode ? !inviteValid : !dirty}
        confirmLoading={saving}
        tertiaryButtonTitle={tabId === MEMBERS_TAB_ID && !inviteMode ? t('steps.members.invite') : undefined}
        onTertiaryButtonClick={readOnly ? undefined : tabId === MEMBERS_TAB_ID && !inviteMode ? () => setInviteMode(true) : undefined}
      >
        <TabStrip onChange={setTabId}>
          <TabStrip.TabHeader id={MEMBERS_TAB_ID} text={t('steps.members.step-title')} />
          <TabStrip.TabHeader id="logs" text={t('steps.logs.step-title')} />
          <TabStrip.TabHeader id="uncontrolled-copy-logs" text={t('steps.uncontrolled-copy-logs.step-title')} />
          {!readOnly && <TabStrip.TabHeader id="settings" text={t('steps.settings.step-title')} />}
          {!readOnly && <TabStrip.TabHeader id="preferences" text={t('steps.preferences.step-title')} />}

          <TabStrip.TabContent forId={MEMBERS_TAB_ID}>
            {inviteMode ? (
              <DistributionInviteNewStep {...sharedProps} onValid={setInviteValid} ref={inviteModalRef} />
            ) : (
              <DistributionMembersStep {...sharedProps} distributionMembers={members} distributionStatus={distribution.status} />
            )}
          </TabStrip.TabContent>
          <TabStrip.TabContent forId="logs">
            <DistributionLogsStep {...sharedProps} distribution={distribution} />
          </TabStrip.TabContent>
          <TabStrip.TabContent forId="uncontrolled-copy-logs">
            <DistributionUncontrolledCopyLogs distribution={distribution} />
          </TabStrip.TabContent>
          {!readOnly && (
            <TabStrip.TabContent forId="settings">
              <DistributionSettingsStep {...sharedProps} />
            </TabStrip.TabContent>
          )}
          {!readOnly && (
            <TabStrip.TabContent forId="preferences">
              <DistributionPreferencesStep {...sharedProps} />
            </TabStrip.TabContent>
          )}
        </TabStrip>
      </StandardModal>
    </ModalContext.Provider>
  );
};

export default DistributionManageModal;
