import { ComponentProps, FC, Fragment } from 'react';
import { StepProps } from './DistributionWizard';
import StandardModal from '../shared/modal/variants/StandardModal';
import { useTranslation } from 'react-i18next';
import WizardStepsIndicator from '../shared/wizard/WizardStepsIndicator';
import { Heading, HeadingSize } from '../shared/text/Heading';
import Checkbox, { SliderSize } from '../shared/form-control/Checkbox';

const DistributionSettingsStep: FC<StepProps> = (props) => {
  const { setCurrentStep, settings } = props;
  const { t } = useTranslation('distribution');

  const Wrapper = setCurrentStep ? StandardModal : Fragment;
  const wrapperProps = setCurrentStep
    ? ({
        title: t('steps.title'),
        confirmButtonTitle: t('steps.next'),
        cancelButtonTitle: t('steps.back'),
        onConfirmClick: () => setCurrentStep('preferences'),
        onCancelClick: () => setCurrentStep('members'),
      } as ComponentProps<typeof StandardModal>)
    : {};

  return (
    <Wrapper {...wrapperProps}>
      {setCurrentStep && (
        <WizardStepsIndicator stepNames={['members', 'settings', 'preferences']} activeStepIndex={1} onStepChange={setCurrentStep} />
      )}

      {setCurrentStep && (
        <div className="mt-2">
          <Heading size={HeadingSize.H5} className="mb-4 font-medium" textColor="text-black">
            {t('steps.settings.step-title')}
          </Heading>
        </div>
      )}

      <div className="mt-4">
        <div className="font-medium text-black">{t('steps.settings.signature.title')}</div>
        <Checkbox
          value={settings.enableAcknowledgementSignature}
          onChange={(value) => props.setSettings((prev) => ({ ...prev, enableAcknowledgementSignature: value }))}
          label={t('steps.settings.signature.enable-acknowledgement')}
          labelBeforeCheckbox
          slider
          sliderSize={SliderSize.S}
        />
      </div>

      <div className="mt-6">
        <div className="font-medium text-black">{t('steps.settings.uncontrolled-copy.title')}</div>
        <Checkbox
          value={settings.automaticSendUncontrolledCopy}
          onChange={(value) => props.setSettings((prev) => ({ ...prev, automaticSendUncontrolledCopy: value }))}
          label={t('steps.settings.uncontrolled-copy.send-complete')}
          labelBeforeCheckbox
          slider
          sliderSize={SliderSize.S}
        />
      </div>
    </Wrapper>
  );
};

export default DistributionSettingsStep;
