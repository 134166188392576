import { FC } from 'react';
import Button, { ButtonSize } from '../shared/form-control/Button';
import { Heading, HeadingSize } from '../shared/text/Heading';
import StartDistributionSvg from '../../assets/images/start-distrabution.svg';
import { useTranslation } from 'react-i18next';

type Props = {
  onStartClick: () => void;
  size?: 'small' | 'medium';
};
const DistributionStartMessage: FC<Props> = ({ onStartClick, size = 'medium' }) => {
  const { t } = useTranslation('distribution');
  return (
    <div className="flex flex-col items-center justify-center gap-6 text-center">
      <img src={StartDistributionSvg} alt={t('steps.start.image-alt')} className={`${size === 'medium' ? 'max-w-[125px]' : 'max-w-[75px]'}`} />
      <div>
        <Heading size={size === 'medium' ? HeadingSize.H3 : HeadingSize.H5} textColor="text-black" className="my-2">
          {t('steps.start.title')}
        </Heading>
        <div className="text-gray-2">{t('steps.start.description')}</div>
      </div>
      <Button size={size === 'medium' ? ButtonSize.M : ButtonSize.S} onClick={onStartClick}>
        {t('steps.start.button')}
      </Button>
    </div>
  );
};

export default DistributionStartMessage;
