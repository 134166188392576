import { ComponentProps, FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { StepProps } from './DistributionWizard';
import StandardModal from '../shared/modal/variants/StandardModal';
import WizardStepsIndicator from '../shared/wizard/WizardStepsIndicator';
import { useTranslation } from 'react-i18next';
import { Heading, HeadingSize } from '../shared/text/Heading';
import UsersGroupSolidIcon from '../shared/icon/UsersGroupSolidIcon';
import { InputStyle } from '../shared/form-control/Input';
import {
  DistributionMember,
  DistributionMemberStatus,
  DistributionMemberStatusKeys,
  DistributionStatus,
  PeopleResponse,
  PeopleType,
} from '../../models/Distribution';
import useDebounce from '../../hooks/useDebounce';
import ClientService from '../../services/ClientService';
import { InputSuggestion } from '../shared/form-control/InputSuggestions';
import { Option } from '../Option';
import XIcon from '../shared/icon/XIcon';
import Tooltip from '../shared/Tooltip';
import ReminderIcon from '../shared/icon/ReminderIcon';
import DateUtils from '../../utils/DateUtils';
import TimeAgo from '../shared/TimeAgo';
import { ImageSize, ProfileImageStack } from '../ownership/ProfileImageStack';
import DistributionService from '../../services/DistributionService';
import { EventSystem } from '../../events/EventSystem';
import { ToastType, useToasts } from '../../contexts/ToastContext';
import StringUtils from '../../utils/StringUtils';

type Props = {
  distributionMembers?: DistributionMember[];
  distributionStatus: DistributionStatus;
};

const DistributionMembersStep: FC<StepProps & Props> = (props) => {
  const { setCurrentStep, members, setMembers, distributionMembers, distributionStatus, readOnly } = props;
  const { t } = useTranslation('distribution');

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchResults, setSearchResults] = useState<PeopleResponse[]>([]);

  const debouncedSearch = useDebounce(searchTerm, 700);
  useEffect(() => {
    if (readOnly) return;

    ClientService.getPeople({ searchTerm: debouncedSearch }).then((res) => {
      setSearchResults(res.data);
    });
  }, [debouncedSearch, readOnly]);

  const suggestions = useMemo(() => {
    return searchResults
      .filter((x) => !members.some((m) => m.id === x.id))
      .map((x) => ({
        id: x.id,
        text: `${x.firstName} ${x.lastName}`,
        value: x.type,
      }));
  }, [searchResults, members]);

  const onPick = useCallback(
    (option: Option<string, string | number>) => {
      setMembers((prev) => [...prev, searchResults.find((x) => x.id === option.id)!]);
      setSearchTerm('');
    },
    [searchResults, setMembers],
  );

  const Wrapper = setCurrentStep ? StandardModal : Fragment;
  const wrapperProps = setCurrentStep
    ? ({
        title: t('steps.title'),
        confirmDisabled: members.length === 0,
        onConfirmClick: () => setCurrentStep('settings'),
        confirmButtonTitle: t('steps.next'),
        tertiaryButtonTitle: t('steps.members.invite'),
        onTertiaryButtonClick: () => setCurrentStep('invite_new'),
      } as ComponentProps<typeof StandardModal>)
    : {};

  return (
    <Wrapper {...wrapperProps}>
      {setCurrentStep && (
        <WizardStepsIndicator stepNames={['members', 'settings', 'preferences']} activeStepIndex={0} onStepChange={setCurrentStep} />
      )}

      <div className="mt-2">
        {setCurrentStep && (
          <Heading size={HeadingSize.H5} className="mb-4 font-medium" textColor="text-black">
            {t('steps.members.step-title')}
          </Heading>
        )}

        {!readOnly && (
          <div className="mt-4">
            <InputSuggestion
              inputConfig={{
                value: searchTerm,
                placeholder: t('steps.members.search.placeholder'),
                style: InputStyle.MINIMAL,
              }}
              onChange={(value) => setSearchTerm(value)}
              onPick={onPick}
              suggestions={suggestions}
              customListRenderer={DefaultListRenderer}
            />
            <div className="text-dpm-12 text-right">{t('steps.members.search.hint')}</div>
          </div>
        )}
      </div>

      {members.length === 0 ? (
        <div className="flex flex-col items-center justify-center gap-6 py-12 text-center">
          <div className="bg-primary-1 rounded-full bg-opacity-10 p-12">
            <UsersGroupSolidIcon className="text-primary-1 h-10 w-10" />
          </div>

          <Heading size={HeadingSize.H4} className="font-medium" textColor="text-black">
            {t('steps.members.empty.title')}
          </Heading>

          <div className="text-gray-2">{t('steps.members.empty.description')}</div>
        </div>
      ) : (
        members.map((member) => {
          const Item = ListRenderer({
            member: distributionMembers?.find((x) => x.memberId === member.id),
            distributionStatus,
            readOnly,
            onRemove: () => setMembers((prev) => prev.filter((x) => x.id !== member.id)),
          });
          return (
            <div key={member.id} className="flex items-center justify-between gap-2 py-2">
              <Item id={member.id} text={`${member.firstName} ${member.lastName}`} value={member.type} />
            </div>
          );
        })
      )}
    </Wrapper>
  );
};

export default DistributionMembersStep;

export const ListRenderer: (outerProps: {
  member?: DistributionMember;
  distributionStatus?: DistributionStatus;
  readOnly?: boolean;
  onRemove?: () => void;
  profileImageSize?: ImageSize;
}) => FC<Option<string, string | number> & { highlightedText?: string }> = (outerProps) =>
  function ListRenderer(props) {
    const { member, distributionStatus, readOnly, onRemove, profileImageSize = ImageSize.S } = outerProps;
    const { id, text, value, highlightedText } = props;
    const [fn, ...lns] = text.split(' ');
    const ln = lns.join(' ');
    const { t } = useTranslation(['distribution']);
    const toasts = useToasts();

    const sendReminder = useCallback(() => {
      if (!member) return;

      DistributionService.sendReminder(member).then(() => {
        EventSystem.fireEvent('distribution-member-reminded', member);
        toasts.addToast({
          title: t('reminders.toasts.sent-title'),
          type: ToastType.SUCCESS,
          description: t('reminders.toasts.sent-description', { name: `${fn} ${ln}` }),
          expiresInMs: 3000,
        });
      });
    }, [fn, ln, member, t, toasts]);

    const [reminderDisabled, reminderTooltipText] = useMemo(() => {
      if (!member?.reminderSentUtc) return [false, t('distribution:steps.members.reminder.send')];

      const date = new Date(member.reminderSentUtc);
      if (DateUtils.getDaysBetween(new Date(), date) >= 1) return [false, t('distribution:steps.members.reminder.send')];

      return [
        true,
        <div key={1}>
          <div className="font-medium">{t('distribution:steps.members.reminder.unavailable')}</div>
          <div>{t('distribution:steps.members.reminder.time-remaining')}</div>
          <div className="text-gray-3">
            <TimeAgo mode="till" date={DateUtils.addDays(1, date)} />
          </div>
        </div>,
      ];
    }, [member?.reminderSentUtc, t]);

    const hideActions = useMemo(
      () => member?.status === DistributionMemberStatus.Acknowledged || member?.status === DistributionMemberStatus.Signed,
      [member?.status],
    );

    const displayText = (
      <>
        {StringUtils.highlightText(text, highlightedText)}{' '}
        {value === PeopleType.Contact && <span className="text-gray-2 [[data-tooltip]_&]:text-white">• {t('steps.members.contact-hint')}</span>}
      </>
    );

    return (
      <div className="flex w-full max-w-full items-center gap-2">
        <ProfileImageStack users={[{ firstName: fn, lastName: ln, id: value === PeopleType.Member ? id : undefined }]} size={profileImageSize} />

        <Tooltip text={displayText} truncatedTextMode>
          {(tooltip) => (
            <div className="flex-grow truncate text-black" {...tooltip}>
              {displayText}
            </div>
          )}
        </Tooltip>

        <div className="flex items-center justify-end gap-2">
          <>
            {member && !!member.statusUtc && (
              <div
                className={`text-dpm-12 flex justify-start ${member.status === DistributionMemberStatus.Acknowledged || member.status === DistributionMemberStatus.Signed ? 'text-semantic-1' : 'text-gray-2'}`}
              >
                {distributionStatus !== DistributionStatus.InActive &&
                  t(
                    DistributionMemberStatusKeys[
                      member.status === DistributionMemberStatus.Created ? DistributionMemberStatus.Pending : member.status
                    ],
                  )}
              </div>
            )}
            {!hideActions && !readOnly && (
              <>
                {member && distributionStatus !== DistributionStatus.InActive && !!member.statusUtc && (
                  <Tooltip text={reminderTooltipText}>
                    {(tooltip) => (
                      <div {...tooltip}>
                        <ReminderIcon
                          className={`${reminderDisabled ? 'text-gray-3 cursor-not-allowed' : 'text-primary-1'} h-6 w-6`}
                          onClick={reminderDisabled ? undefined : sendReminder}
                        />
                      </div>
                    )}
                  </Tooltip>
                )}

                {onRemove && (
                  <Tooltip text={t('distribution:delete-member.title')}>
                    {(tooltip) => (
                      <div {...tooltip}>
                        <XIcon className="text-primary-1 h-5 w-5" onClick={onRemove} />
                      </div>
                    )}
                  </Tooltip>
                )}
              </>
            )}
          </>
        </div>
      </div>
    );
  };

const DefaultListRenderer = ListRenderer({});
