import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { DistributionResponse } from '../../models/Distribution';
import { UncontrolledCopyLog } from '../../models/Document';
import useFetchClientContacts from '../../hooks/useFetchClientContacts';
import useFetchClientUsers from '../../hooks/useFetchClientUsers';
import { toRecord } from '../../utils/ListUtils';
import DocumentService from '../../services/DocumentService';
import { useTranslation } from 'react-i18next';
import { Heading, HeadingSize } from '../shared/text/Heading';
import SimpleTable, { ColumnConfig } from '../shared/data-grid/SimpleTable';
import ProfileCard from '../shared/profile/ProfileCard';
import ProfileAvatar, { ImageSize } from '../shared/profile-image/ProfileAvatar';
import DateUtils from '../../utils/DateUtils';
import Tooltip from '../shared/Tooltip';
import EmptyStateImg from '../../assets/images/distribution-uncontrolled-copies-empty.svg';

type Props = {
  distribution: DistributionResponse;
};

const DistributionUncontrolledCopyLogs: FC<Props> = (props) => {
  const { distribution } = props;
  const { data: contacts } = useFetchClientContacts();
  const { data: clientUsers } = useFetchClientUsers();

  const [logs, setLogs] = useState<UncontrolledCopyLog[]>([]);
  const { t } = useTranslation('distribution');

  useEffect(() => {
    DocumentService.getUncontrolledCopyLogs({
      clientFormId: distribution.clientFormId,
      version: distribution.clientFormVersion,
      pageNumber: 1,
      pageSize: 999,
    }).then((res) => {
      setLogs(res.data);
    });
  }, [distribution.clientFormId, distribution.clientFormVersion]);

  const people = useMemo(() => {
    const values = [...(contacts ?? []), ...(clientUsers ?? [])].map((x) => ({ id: x.id, fullName: `${x.firstName} ${x.lastName}` })) ?? [];
    return toRecord(values, 'id');
  }, [clientUsers, contacts]);

  const columns = useMemo<ColumnConfig<UncontrolledCopyLog>[]>(() => {
    if (!distribution || !logs.length) return [];

    const recipient: ColumnConfig<UncontrolledCopyLog> = {
      key: 'recipientEmail',
      label: t('steps.uncontrolled-copy-logs.table.recipient'),
      sortable: true,
      render(log) {
        return (
          <ProfileCard
            email={log.recipientEmail}
            name={people?.[log.memberId ?? '']?.fullName ?? ''}
            userId={log.memberId}
            peopleType={log.memberType}
          />
        );
      },
    };

    const emailContent: ColumnConfig<UncontrolledCopyLog> = {
      key: 'subject',
      label: t('steps.uncontrolled-copy-logs.table.subject'),
      sortable: true,
      render(log) {
        const msg = (
          <>
            <span className="font-medium">{log.subject}:</span> <span>{log.message}</span>
          </>
        );
        return (
          <Tooltip text={msg}>
            {(tooltip) => (
              <div {...tooltip} className="truncate">
                {msg}
              </div>
            )}
          </Tooltip>
        );
      },
    };

    const sentBy: ColumnConfig<UncontrolledCopyLog> = {
      key: 'memberId',
      label: t('steps.uncontrolled-copy-logs.table.sent-by'),
      sortable: true,
      align: 'center',
      render(log) {
        return <ProfileAvatar user={clientUsers?.find((x) => x.id === log.createdById)} size={ImageSize.S} />;
      },
    };

    const sentOn: ColumnConfig<UncontrolledCopyLog> = {
      key: 'createdUtc',
      label: t('steps.uncontrolled-copy-logs.table.sent-on'),
      sortable: true,
      align: 'right',
      render(log) {
        return <>{DateUtils.formatDateOrTime(new Date(log.createdUtc))}</>;
      },
    };

    return [recipient, emailContent, sentBy, sentOn];
  }, [clientUsers, distribution, logs.length, people, t]);

  const gridStyleCalc = useCallback((columnCount: number) => {
    return `minmax(100px, 2fr) minmax(150px, 3fr) repeat(${columnCount - 2}, minmax(50px, 1fr))`;
  }, []);

  return (
    <div>
      {logs.length === 0 && (
        <div className="flex flex-col items-center gap-2 p-4 text-center">
          <img src={EmptyStateImg} alt="" className="m-4" />
          <Heading size={HeadingSize.H2} actualSize={HeadingSize.H4} textColor="text-black" className="font-medium">
            {t('steps.uncontrolled-copy-logs.empty-title')}
          </Heading>
          <div>{t('steps.uncontrolled-copy-logs.empty-subtitle')}</div>
        </div>
      )}
      {logs.length > 0 && (
        <SimpleTable data={logs} columns={columns} defaultSortKey="createdUtc" defaultSortDirection="descending" gridStyleCalc={gridStyleCalc} />
      )}
    </div>
  );
};

export default DistributionUncontrolledCopyLogs;
