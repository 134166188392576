import { Dispatch, FC, ReactElement, SetStateAction, useCallback, useMemo, useState } from 'react';
import { ModalContext } from '../../contexts/ModalContext';
import StartDistributionStep from './StartDistributionStep';
import DistributionMembersStep from './DistributionMembersStep';
import {
  DistributionPreferences,
  DistributionSettings,
  DistributionStatus,
  DistrubutiuonSchedulingType,
  PeopleResponse,
  PeopleType,
} from '../../models/Distribution';
import DistributionSettingsStep from './DistributionSettingsStep';
import DistributionInviteNewStep from './DistributionInviteNewStep';
import DistributionPreferencesStep from './DistributionPreferencesStep';
import { DateInterval } from '../../models/DateInterval';
import DistributionService from '../../services/DistributionService';
import { ToastType, useToasts } from '../../contexts/ToastContext';
import { Trans, useTranslation } from 'react-i18next';
import { EventSystem } from '../../events/EventSystem';

type Props = {
  clientFormId: string;
  clientFormVersion: number;
  clientFormSubtitle: string;
  open: boolean;
  onClose: () => void;
  startingStep?: Steps;
};

const stepNames = ['start', 'members', 'settings', 'invite_new', 'preferences'] as const;

type ExtractReturnTypes<T> = T extends IterableIterator<infer R> ? R : never;
type Steps = ExtractReturnTypes<ReturnType<(typeof stepNames)['values']>>;

export type StepProps = {
  setCurrentStep?: (step: Steps) => void;
  members: PeopleResponse[];
  setMembers: Dispatch<SetStateAction<PeopleResponse[]>>;
  settings: DistributionSettings;
  setSettings: Dispatch<SetStateAction<DistributionSettings>>;
  preferences: DistributionPreferences;
  setPreferences: Dispatch<SetStateAction<DistributionPreferences>>;
  doCreate?: () => void;
  readOnly?: boolean;
};

const DistributionWizard: FC<Props> = (props) => {
  const { open, onClose, startingStep = 'start', clientFormId, clientFormVersion, clientFormSubtitle } = props;
  const [currentStep, setCurrentStep] = useState<Steps>(startingStep);
  const toasts = useToasts();
  const { t } = useTranslation('distribution');

  // state
  const [members, setMembers] = useState<PeopleResponse[]>([]);
  const [settings, setSettings] = useState<DistributionSettings>({
    enableAcknowledgementSignature: true,
    automaticSendUncontrolledCopy: true,
  });
  const [preferences, setPreferences] = useState<DistributionPreferences>({
    scheduleType: DistrubutiuonSchedulingType.Immediate,
    scheduleFixedDate: null,
    reminderFrequencyValue: null,
    reminderFrequencyInterval: null,
    notifyOnReady: true,
    notifyOnSuccessful: false,
    linkExpirationAmount: 2,
    linkExpirationInterval: DateInterval.WEEK,
    linkExpirationDate: null,
  });

  const onCloseInternal = useCallback(() => {
    setCurrentStep(startingStep);
    setMembers([]);
    onClose();
  }, [onClose, startingStep]);

  const doCreate = useCallback(() => {
    DistributionService.createDistribution({
      clientFormId,
      clientFormVersion: clientFormVersion,
      members: {
        [PeopleType.Member]: members.filter((x) => x.type === PeopleType.Member).map((m) => m.id),
        [PeopleType.Contact]: members.filter((x) => x.type === PeopleType.Contact).map((m) => m.id),
      },
      preferences,
      settings,
    }).then((res) => {
      onCloseInternal();
      EventSystem.fireEvent('distribution-updated', res.data);
      toasts.addToast({
        title: (
          <span className="font-normal">
            <Trans
              t={t}
              i18nKey="steps.toasts.created"
              values={{ name: clientFormSubtitle }}
              components={{ Bold: <span className="font-medium" /> }}
            />
          </span>
        ),
        type: ToastType.SUCCESS,
        expiresInMs: 3000,
      });
    });
  }, [clientFormId, clientFormSubtitle, clientFormVersion, members, onCloseInternal, preferences, settings, t, toasts]);

  const steps = useMemo<Record<Steps, ReactElement>>(() => {
    const props: StepProps = {
      setCurrentStep,
      members,
      setMembers,
      settings,
      setSettings,
      preferences,
      setPreferences,
      doCreate,
    };

    return {
      start: <StartDistributionStep {...props} />,
      members: <DistributionMembersStep {...props} distributionStatus={DistributionStatus.InActive} />,
      settings: <DistributionSettingsStep {...props} />,
      preferences: <DistributionPreferencesStep {...props} />,
      invite_new: <DistributionInviteNewStep {...props} />,
    };
  }, [doCreate, members, preferences, settings]);

  return (
    <ModalContext.Provider value={{ open, onClose: onCloseInternal, modalWidth: 'w-2/5 min-w-[900px]' }}>{steps[currentStep]}</ModalContext.Provider>
  );
};

export default DistributionWizard;
