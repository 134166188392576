import { FC } from 'react';
import { StepProps } from './DistributionWizard';
import DistributionLogs from './DistributionLogs';
import { DistributionResponse } from '../../models/Distribution';

const DistributionLogsStep: FC<StepProps & { distribution: DistributionResponse }> = (props) => {
  const { distribution } = props;
  return <DistributionLogs distribution={distribution} mode={'minimal'} />;
};

export default DistributionLogsStep;
