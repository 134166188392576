import { FC, useCallback, useMemo } from 'react';
import {
  DistributionMember,
  DistributionResponse,
  DistributionMemberStatus,
  DistributionMemberStatusKeys,
  PeopleResponse,
  PeopleType,
  DistributionStatus,
  DistrubutiuonSchedulingType,
} from '../../models/Distribution';
import useFetchClientContacts from '../../hooks/useFetchClientContacts';
import useFetchClientUsers from '../../hooks/useFetchClientUsers';
import { useTranslation } from 'react-i18next';
import DateUtils from '../../utils/DateUtils';
import { Heading, HeadingSize } from '../shared/text/Heading';
import Tooltip from '../shared/Tooltip';
import SimpleTable, { ColumnConfig } from '../shared/data-grid/SimpleTable';
import ProfileCard from '../shared/profile/ProfileCard';
import { ProfileImageStack } from '../ownership/ProfileImageStack';
import DownloadIcon from '../shared/icon/DownloadIcon';
import DataExportService from '../../services/DataExportService';

type Log = PeopleResponse & DistributionMember & { createdBy: string; createdById: string; fullname: string; scheduledDate: string };
type BaseProps = {
  distribution?: DistributionResponse;
  mode?: 'minimal' | 'normal';
};

type NormalModeProps = BaseProps & {
  mode: 'normal';
  documentTitle: string;
};

type MinimalModeProps = BaseProps & {
  mode?: 'minimal';
  documentTitle?: string;
};

type Props = NormalModeProps | MinimalModeProps;

const DistributionLogs: FC<Props> = (props) => {
  const { documentTitle, distribution, mode = 'normal' } = props;
  const { data: contacts = [] } = useFetchClientContacts();
  const { data: clientUsers = [] } = useFetchClientUsers();
  const { t } = useTranslation(['distribution']);

  const logs = useMemo<Log[]>(() => {
    if (!distribution) {
      return [];
    }
    return distribution.members.map((member) => {
      const person =
        member.type === PeopleType.Contact ? contacts.find((x) => x.id === member.memberId) : clientUsers!.find((x) => x.id === member.memberId);
      const log = {
        id: member.memberId,
        firstName: (person && person.firstName) || '',
        lastName: (person && person.lastName) || '',
        email: person?.email || '',
        createdBy: distribution.createdBy,
        createdById: distribution.createdById,
        ...member,
      } as Log;

      return { ...log, fullname: `${log.firstName} ${log.lastName}` };
    });
  }, [clientUsers, contacts, distribution]);

  const columns: ColumnConfig<Log>[] = useMemo(() => {
    if (!distribution) return [];

    const baseColumns: ColumnConfig<Log>[] = [
      {
        key: 'fullname',
        label: t('distribution:logs.headings.recipient'),
        render: (log: Log) => <ProfileCard email={log.email!} name={log.fullname} userId={log.memberId} peopleType={log.type} />,
        sortable: true,
      },
      {
        key: 'status',
        label: t('distribution:logs.headings.status'),
        render: (log: Log) => (
          <div className="whitespace-nowrap">
            <span
              className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${log.status === DistributionMemberStatus.Acknowledged || log.status === DistributionMemberStatus.Signed ? 'bg-semantic-1 text-semantic-1 bg-opacity-10' : 'bg-gray-2 text-gray-2 bg-opacity-5'}`}
            >
              {t(DistributionMemberStatusKeys[log.status === DistributionMemberStatus.Created ? DistributionMemberStatus.Pending : log.status])}
            </span>
          </div>
        ),
        sortable: true,
      },
    ];

    const sentOnColumn: ColumnConfig<Log> = {
      key: 'notifiedOnUtc',
      label: t('distribution:logs.headings.sent-on'),
      render: (log: Log) => {
        const notifiedOnDate = log.notifiedOnUtc ? DateUtils.formatDateTime(new Date(log.notifiedOnUtc)) : '-';
        return (
          <Tooltip text={notifiedOnDate} truncatedTextMode>
            {(tooltip) => (
              <div {...tooltip} className="truncate whitespace-nowrap">
                {notifiedOnDate}
              </div>
            )}
          </Tooltip>
        );
      },
      sortable: true,
    };

    const createdByColumn: ColumnConfig<Log> = {
      key: 'createdBy',
      label: t('distribution:logs.headings.sent-by'),
      render: (log: Log) => {
        const [fn, ...lns] = log.createdBy.split(' ');
        const ln = lns.join(' ');
        return <ProfileImageStack users={[{ firstName: fn, lastName: ln, id: log.createdById }]} />;
      },
      sortable: true,
      align: 'center',
    };

    const statusDateColumn: ColumnConfig<Log> = {
      key: 'statusUtc',
      label: t('distribution:logs.headings.date'),
      render: (log: Log) => {
        const acknowledgedDate =
          log.statusUtc && (log.status === DistributionMemberStatus.Acknowledged || log.status === DistributionMemberStatus.Signed)
            ? DateUtils.formatDateTime(new Date(log.statusUtc))
            : '-';
        return (
          <Tooltip text={acknowledgedDate} truncatedTextMode>
            {(tooltip) => (
              <div {...tooltip} className="truncate whitespace-nowrap">
                {acknowledgedDate}
              </div>
            )}
          </Tooltip>
        );
      },
      sortable: true,
    };

    const scheduleDateColumn: ColumnConfig<Log> = {
      key: 'scheduledDate',
      label: t('distribution:logs.headings.schedule-date'),
      render: () => {
        const scheduledText =
          (distribution.scheduleType === DistrubutiuonSchedulingType.FixedDate && distribution.startDate) ||
          (distribution.scheduleType === DistrubutiuonSchedulingType.EffectiveDate && distribution.startDate)
            ? t('status-tooltips.descriptions.in-active.date', {
                date: DateUtils.formatDate(new Date(distribution.startDate)),
              })
            : t('status-tooltips.descriptions.in-active.immediate');
        return (
          <Tooltip text={scheduledText} truncatedTextMode>
            {(tooltip) => (
              <div {...tooltip} className="truncate whitespace-nowrap">
                {scheduledText}
              </div>
            )}
          </Tooltip>
        );
      },
      sortable: true,
    };

    if (distribution.status === DistributionStatus.Active || distribution.status === DistributionStatus.Completed) {
      // Order: Recipient, Added By, Sent On, Status, Date acknowledged
      baseColumns.splice(1, 0, createdByColumn);
      if (distribution.preferences.notifyOnReady) {
        baseColumns.splice(2, 0, sentOnColumn);
        baseColumns.splice(4, 0, statusDateColumn);
      } else {
        baseColumns.splice(3, 0, statusDateColumn);
      }
    } else {
      // Order: Recipient, Scheduled Date, Status
      baseColumns.splice(1, 0, scheduleDateColumn);
    }

    return baseColumns;
  }, [distribution, t]);

  const downloadLogs = useCallback(() => {
    DataExportService.downloadDistributionLogs(distribution!.id).then((content) => {
      const url = URL.createObjectURL(content);
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(content);
      downloadLink.download = `${t('logs.download.file', { document: documentTitle })}.pdf`;
      downloadLink.click();
      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100);
    });
  }, [distribution, documentTitle, t]);

  const gridStyleCalc = useCallback(
    (columns: number) => {
      if (distribution?.status === DistributionStatus.Active || distribution?.status === DistributionStatus.Completed) {
        return `minmax(150px, 2fr) repeat(${columns - 2}, minmax(100px, 1fr)) minmax(150px, 1fr)`;
      }
      return `minmax(150px, 2fr) repeat(${columns - 2}, minmax(100px, 1fr)) minmax(50px, 0.5fr)`;
    },
    [distribution?.status],
  );

  return (
    <div className={`${mode === 'normal' ? 'py-4 pl-4' : ''}`}>
      {mode === 'normal' && (
        <Heading size={HeadingSize.H2} actualSize={HeadingSize.H4}>
          {t('distribution:logs.title')}{' '}
          {(distribution?.status === DistributionStatus.Active || distribution?.status === DistributionStatus.Completed) && (
            <Tooltip text={t('logs.download.tooltip')}>
              {(tooltip) => (
                <span {...tooltip}>
                  <DownloadIcon className="ml-2 h-6 w-6" onClick={downloadLogs} />
                </span>
              )}
            </Tooltip>
          )}
        </Heading>
      )}
      <div className={`overflow-x-auto ${mode === 'normal' ? 'mt-2' : ''}`}>
        <SimpleTable data={logs} columns={columns} defaultSortKey="fullname" defaultSortDirection="ascending" gridStyleCalc={gridStyleCalc} />
      </div>
    </div>
  );
};

export default DistributionLogs;
